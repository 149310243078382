import React from "react";
import mainLogo from "../logo.svg";

const Loader = () => {
  return (
    <React.Fragment>
      <div
        className="d-flex justify-content-center align-items-center bg-white"
        style={{ height: "100vh" }}
      >
        <div>
          <img src={mainLogo} alt="" height="50" />
        </div>
        <i
          className="bx bx-loader bx-spin fs-3"
          style={{ color: "#0180f5" }}
        ></i>
      </div>
    </React.Fragment>
  );
};

export default Loader;
